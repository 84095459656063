import router from '@/router'
import { BUILD_NUMBER } from '@/config'

export function appendSitePrefix(url): string {
  return '/' + router.currentRoute.params.siteId + url
}

export function getCurrentSite(): number {
  return parseInt(router.currentRoute.params.siteId)
}

export function isCurrentSiteId(siteId?: number | null): boolean {
  return siteId === getCurrentSite()
}

export function checkBuild(): void {
  try {
    const cacheBuster = new Date().getTime()
    fetch(`/build.txt?cache-buster=${cacheBuster}`)
      .then((response) => response.text())
      .then((data) => {
        const NEW_BUILD_NUMBER = data.trim()
        if (NEW_BUILD_NUMBER && BUILD_NUMBER && BUILD_NUMBER !== NEW_BUILD_NUMBER) {
          console.warn('Build number not match:', BUILD_NUMBER, data.trim())
          document.dispatchEvent(new Event('newBuild'))
        }
      })
      .catch((error) => {
        console.error('Error fetching build.txt:', error)
      })
  } catch (error) {
    console.error('Error running checkBuild:', error)
  }
}
