























































































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { notifications } from '@/store/modules/notifications'
import { VERSION, BUILD_NUMBER } from '@/config'

@Component
export default class NavigationDrawer extends Vue {
  @PropSync('drawer') syncDrawer: boolean
  @Prop({ required: true, default: {} }) items!: Record<string, string>
  @Prop({ required: true, default: [] }) quickActions!: Array<Record<string, unknown>>
  userMenu = false
  version = `${VERSION} ${BUILD_NUMBER}`

  get isMultiSiteUser(): boolean {
    return Object.keys(vxm.user.tokens).length > 1
  }

  get userLinks() {
    const user = this.user
    return [
      {
        title: 'Preferences',
        url: `/${user.profile.id}/user/edit_all`,
      },
      {
        title: 'My settings',
        url: `/${user.profile.id}/user_settings/main`,
      },
      {
        title: 'Change password',
        url: `/${user.profile.id}/user/change_pw`,
      },
    ].map((route) => ({
      title: this.$t(route.title),
      url: this.appendSitePrefix(route.url),
    }))
  }

  get sitePrefix() {
    return '/' + this.$route.params.siteId
  }

  get user() {
    return vxm.user
  }

  get siteName() {
    return this.user?.tokenPayload?.siteName ?? ''
  }

  get notifications() {
    return vxm.notifications
  }

  created() {
    window.addEventListener('click', this.handleClick)
  }

  destroyed() {
    window.removeEventListener('click', this.handleClick)
  }

  isExternalUrl(url: string): boolean {
    const isExternal = /^https?:\/\//i
    return isExternal.test(url)
  }

  bindUrl(url: string, onlyTo = false) {
    const isAbsolute = /^([a-z]+:\/\/|\/\/)/i
    if (isAbsolute.test(url)) {
      if (onlyTo) {
        return {
          to: url,
        }
      }
      return {
        href: url,
      }
    }
    return {
      to: this.appendSitePrefix(url),
    }
  }

  handleClick(e) {
    if (this.syncDrawer && !this.$el.contains(e?.target)) {
      this.closeMenu()
    }
  }

  closeMenu(): void {
    this.syncDrawer = false
  }

  appendSitePrefix(url: string) {
    return this.sitePrefix + url
  }

  @Watch('syncDrawer')
  onDrawerChanged() {
    if (!this.syncDrawer) {
      this.userMenu = false
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(_newVal: unknown) {
    this.syncDrawer = false
  }
}
