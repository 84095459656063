


































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import Overlay from './components/Overlay.vue'
import NavigationBar from './components/NavigationBar.vue'
import AlertContainer from './components/AlertContainer.vue'
import { vxm } from '@/store'
import PaymentWarning from '@/components/overlays/PaymentWarning.vue'
import { fetchStyles } from '@/utils/styleFetcher'
import i18n from '@/i18n'
import { DialogComponent } from './types/Dialog'
import strategies from './messages/root'
import { checkBuild } from '@/utils/routeUtils'

@Component({
  components: { PaymentWarning, AlertContainer, Overlay, NavigationBar },
})
export default class App extends Vue {
  private tooltip = true
  private loading = true
  private dialogs: DialogComponent[] = []
  private isNewBuild = false

  private get user() {
    return vxm.user
  }

  private get profile() {
    return this.user.profile
  }

  private async created() {
    const styles = await fetchStyles()
    if (styles?.colors) {
      this.$vuetify.theme.themes.light = styles.colors
      this.$vuetify.theme.themes.dark = styles.colors
    }
    document.title = styles?.title ?? 'EONTYRE'
    if (styles?.redirect) {
      // If accessing whitelabel via an alternative domain, we redirect to primary domain
      // Do note that the "should site redirect to a whitelabel" check is elsewhere (store -> user -> setCurrentSite)
      document.location.href = document.location.href.replace(document.location.hostname, styles.redirect)
    }
    vxm.user.cleanTokens()
    this.loading = false
    document.addEventListener('newBuild', () => {
      this.isNewBuild = true
    })

    checkBuild()
    setInterval(() => {
      checkBuild()
    }, 3600000 * 3) // Check every 3 hours
  }

  private mounted(): void {
    window.addEventListener('message', this.handlePostMessage)
  }

  private beforeDestroy(): void {
    window.removeEventListener('message', this.handlePostMessage)
  }

  private handlePostMessage(event: MessageEvent): void {
    // Messages strategies
    // - Booking - Need this for legacy-gui to be able to pop up our booking dialog
    // - Create Tags - Need this for legacy-gui to be able to pop up our create tag dialog
    // - Supplier Order Status - Update supplier order status when the status = 'WaitingForSupplierInput'
    // - LegacyFrameLoad

    if (event.data?.type) {
      const strategy = strategies[event.data.type]
      if (strategy?.handle) {
        strategy.handle(this, event.data)
      }
    }
  }

  private reload() {
    window.location.reload()
  }

  @Watch('profile', { immediate: true })
  private onProfileChange(profile) {
    const browserLang = navigator.languages !== undefined ? navigator.languages[0] : navigator.language
    const profileLang = profile.language || profile.lang
    const language = (profileLang || browserLang || 'en_US').replace(/(_|-).+$/, '')
    i18n.setLanguage(language)
  }
}
