import { io, Socket } from 'socket.io-client'
import { GUI_SOCKET_URL } from '@/config'
import VueAxiosInterface from '@asmadsen/vue-axios/types/VueAxiosInterface'

/*
{
    "id": 8,
    "customer_id": 3541698,
    "car_id": 19418,
    "car_brand": null,
    "tyre_hotel_id": 13783,
    "licenseplate": "ABC123",
    "name": "Åge Engelstrand Westbø",
    "queue": "expedite",
    "data": {
        "id": "3541698",
        "licenseplate": "ABC123",
        "type": "2",
        "name": "Åge Engelstrand Westbø",
        "address_1": "Steingata 80",
        "address_2": "",
        "zipcode": "4009",
        "city": "Stavanger",
        "country": "NO",
        "email": "",
        "mobile": "+4790831027",
        "driver_id": "22134",
        "driver_name": "Åge Engelstrand Westbø",
        "driver_mobile": "+4790831027",
        "driver_email": "",
        "reg_no": ""
    },
    "queues": {
        "expedite": {
            "person_id": 8,
            "queue": "expedite",
            "status": 1,
            "start_at": "2022-03-27 23:33:10",
            "expedite_at": null,
            "call_at": null,
            "done_at": null,
            "start_by": null,
            "expedite_by": null,
            "done_by": null,
            "target": null,
            "id": 11
        }
    },
    "order_id": 42235,
    "booking": {
      "_id": 1084521,
      "reg_no": "ABC123",
      "start_datetime": "2022-03-28 05:15:00",
      "booking_no": "0ED6B4",
      "is_dropin": false,
      "start_time": "07:15"
    },
    "timing": {
        "backendSend": 1648423992249,
        "socketSend": 1648423992249
    }
}
 */

/*
{
    "people": [
        {
            "id": 8,
            "customer_id": 3541698,
            "car_id": 19418,
            "car_brand": null,
            "tyre_hotel_id": 13783,
            "licenseplate": "ABC123",
            "name": "Åge Engelstrand Westbø",
            "queue": "expedite",
            "data": {
                "id": "3541698",
                "licenseplate": "ABC123",
                "type": "2",
                "name": "Åge Engelstrand Westbø",
                "address_1": "Steingata 80",
                "address_2": "",
                "zipcode": "4009",
                "city": "Stavanger",
                "country": "NO",
                "email": "",
                "mobile": "+4790831027",
                "driver_id": "22134",
                "driver_name": "Åge Engelstrand Westbø",
                "driver_mobile": "+4790831027",
                "driver_email": "",
                "reg_no": ""
            },
            "queues": {
                "expedite": {
                    "person_id": 8,
                    "queue": "expedite",
                    "status": 1,
                    "start_at": "2022-03-27 23:33:10",
                    "expedite_at": null,
                    "call_at": null,
                    "done_at": null,
                    "start_by": null,
                    "expedite_by": null,
                    "done_by": null,
                    "target": null,
                    "id": 11
                }
            },
            "order_id": 42267,
            "booking": null
        }
    ],
    "expediting": null,
    "session": null,
    "timing": {
        "backendSend": 1648424732239,
        "socketSend": 1648424732240
    }
}
 */

class BookingMessage {
  // eslint-disable-next-line camelcase
  public _id: number
  // eslint-disable-next-line camelcase
  public reg_no: string
  // eslint-disable-next-line camelcase
  public start_datetime: string
  // eslint-disable-next-line camelcase
  public booking_no: string
  // eslint-disable-next-line camelcase
  public is_dropin: boolean
  // eslint-disable-next-line camelcase
  public start_time: string
}

export class PersonInQueueMessage {
  public id: number
  // eslint-disable-next-line camelcase
  public person_id: number
  public queue: string
  public status: number
  // eslint-disable-next-line camelcase
  public start_at: string
  // eslint-disable-next-line camelcase
  public expedite_at: string
  // eslint-disable-next-line camelcase
  public call_at: string
  // eslint-disable-next-line camelcase
  public done_at: string
  // eslint-disable-next-line camelcase
  public start_by: string
  // eslint-disable-next-line camelcase
  public expedite_by: string
  // eslint-disable-next-line camelcase
  public done_by: string
  public target: string
}

export class UpdateMessage {
  public id: number
  // eslint-disable-next-line camelcase
  public customer_id: number
  // eslint-disable-next-line camelcase
  public car_id: number
  // eslint-disable-next-line camelcase
  public car_brand: string
  // eslint-disable-next-line camelcase
  public tyre_hotel_id: number
  // eslint-disable-next-line camelcase
  public tyre_hotel_v2: boolean
  // eslint-disable-next-line camelcase
  public order_id: number
  public licenseplate: string
  public name: string
  public queue: string
  public booking: BookingMessage
  public queues: {
    expedite: PersonInQueueMessage
    garage: PersonInQueueMessage
  }
}

export class DumpMessage {
  public people: Array<UpdateMessage>
  public expediting: number
}

export type ServerToClientEvents = {
  'invalid-token': () => void
  'customer-queue.update': (data: UpdateMessage) => void
  'customer-queue.full': (data: DumpMessage) => void
  authenticated: (data: { result: boolean }) => void
}

export type ClientToServerEvents = {
  token: ({ token: string }) => void
}

export class GuiSocket {
  private socket: Socket<ServerToClientEvents, ClientToServerEvents>
  public connected = false
  public authenticated = false
  private socketToken = ''

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect()
    }
    this.connected = false
    this.authenticated = false
    this.socket = null
  }

  public connect(
    axios: VueAxiosInterface,
    onAuthenticated: () => void,
    onUpdate: (data: UpdateMessage) => void,
    onDump: (data: DumpMessage) => void,
  ): void {
    this.socket = io(GUI_SOCKET_URL)

    this.socket.on('disconnect', () => {
      this.connected = false
      this.authenticated = false
    })

    this.socket.on('connect', () => {
      this.connected = true
      this.authenticated = false
      this.refreshToken(axios, () => {
        this.socket.emit('token', { token: this.socketToken })
      })
    })

    this.socket.on('authenticated', (data) => {
      if (data.result) {
        this.authenticated = true
        onAuthenticated()
      } else {
        this.socketToken = ''
        this.authenticated = false
      }
    })

    // Socket messages

    this.socket.on('customer-queue.update', (data) => {
      onUpdate(data)
    })

    this.socket.on('customer-queue.full', (data) => {
      onDump(data)
    })
  }

  private refreshToken(axios: VueAxiosInterface, callback: () => void) {
    axios
      .get('/v4/site/socket/token')
      .then((response) => {
        if (response.data?.data?.token) {
          console.log('XX got token')
          this.socketToken = response.data.data.token
          callback()
        } else {
          console.log('XX miss token')
          setTimeout(() => {
            this.refreshToken(axios, callback)
          }, 10000)
        }
      })
      .catch((_err) => {
        console.log('XX err token')
        setTimeout(() => {
          this.refreshToken(axios, callback)
        }, 10000)
      })
  }
}
